export enum PropertyType {
  NUMBER = 0,
  TEXT = 1,
  DATE = 2,
  // USER = 3,
  // ROLE = 4,
  // ENTITY = 5,
  MEDIA = 6,
  // ID = 7,
  SELECT = 8,
  // FORMULA = 9,
  BOOLEAN = 10,
  MULTI_SELECT = 11,
  MULTI_TEXT = 12,
  RANGE_NUMBER = 13,
  RANGE_DATE = 14,
  FORMULA = 15,
}
